import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Posts from "../components/Posts";
import { ContentWrapper } from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb";

const categoriesTemplate = (props) => {
  const {
    pageContext: { category },
  } = props;
  const {
    data: {
      categories: { nodes: posts },
    },
  } = props;
  return (
    <Layout>
      <ContentWrapper>
        <Breadcrumb
          subPageName="Blog"
          subPagePath="/blog/"
          pageName={`Category: ${category}`}
        />
        <Posts
          posts={posts}
          key={posts.id}
          title={`View the latest posts on CSS ${category}`}
        />
      </ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query GetCategories($category: String) {
    categories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      nodes {
        frontmatter {
          author
          category
          title
          slug
        }
        id
      }
    }
  }
`;

export default categoriesTemplate;
