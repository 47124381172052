import React from "react"
import Post from "./Post"
import { HeaderTwo } from "../Headers"
import styled from "styled-components"
import { perfectFourth } from "../../utils"

const Posts = ({ posts, title }) => {
  return (
    <BlogPostsWrapper>
      <HeaderTwo>
        {title}
        <span>.</span>
      </HeaderTwo>
      {posts.map(post => {
        return <Post key={post.id} {...post} />
      })}
    </BlogPostsWrapper>
  )
}

export const BlogPostsWrapper = styled.section`
  padding-block-end: ${perfectFourth[400]};
  padding-inline-start: ${perfectFourth[200]};
  padding-inline-end: ${perfectFourth[200]};
`

export default Posts
