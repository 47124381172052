import React from "react"
import styled from "styled-components"
import { perfectFourth, navy, teal, transition, typeScale } from "../../utils"
import { Link } from "gatsby"

const Post = ({ frontmatter }) => {
  const { title, slug, category, author } = frontmatter
  return (
    <PostWrapper>
      <PostInfo>
        By {author} -
        <span>
          {category.map((tag, i) => [
            <Link to={`/blog/categories/${tag}/`} key={i}>
              {" "}
              {tag}
              {i < category.length - 1 ? ", " : ""}
            </Link>,
          ])}
        </span>
      </PostInfo>
      <PostTitle>
        <Link to={`/blog/${slug}`}>{title}</Link>
      </PostTitle>
    </PostWrapper>
  )
}

export const PostWrapper = styled.article`
  margin-block-end: ${perfectFourth[300]};
  margin-block-start: ${perfectFourth[700]};
`

export const PostInfo = styled.p`
  color: ${navy[400]};
  span {
    color: ${teal[800]};
    text-transform: capitalize;
  }
  a {
    color: ${teal[700]};
    text-decoration: none;
    transition: ${transition.reg};
    &:hover {
      color: ${teal[600]};
    }
  }
`

export const PostTitle = styled.p`
  font-size: ${typeScale.header3};
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 1.2;
  a {
    color: ${navy[500]};
    text-decoration-color: ${navy[200]};
    transition: ${transition.reg};
    &:hover {
      text-decoration-color: ${navy[300]};
    }
  }
`

export default Post
