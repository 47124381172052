import React from "react"
import styled from "styled-components"
import {
  yellow,
  transition,
  neutral,
  width,
  perfectFourth,
  typeScale,
  navy,
} from "../utils"
import { Link } from "gatsby"

const Breadcrumb = ({ subPageName, subPagePath, pageName }) => {
  return (
    <Trail>
      <Link to="/">Home</Link> / <Link to={subPagePath}>{subPageName}</Link> /{" "}
      {pageName}
    </Trail>
  )
}

const Trail = styled.div`
  position: absolute;
  top: -1.5rem;
  max-inline-size: ${width.reg};
  margin: auto;
  padding-inline-start: ${perfectFourth[200]};
  padding-inline-end: ${perfectFourth[200]};
  font-size: ${typeScale.smallText};
  letter-spacing: 0.5px;
  color: ${navy[500]};
  a {
    color: ${navy[400]};
    text-decoration-color: ${yellow[500]};
    transition: ${transition.reg};
    background: transparent;
    background: linear-gradient(
      to top,
      ${yellow[400]} 50%,
      ${neutral[100]} 50%
    );
    background-size: 100% 200%;
    background-position: right top;
    &:hover {
      color: ${navy[600]};
      background-position: right bottom;
    }
  }
`

export default Breadcrumb
